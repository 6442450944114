import React from 'react';

const Libray = () => {
    return (
        <div>
            <h2>This page Comming soon..</h2>
        </div>
    );
};

export default Libray;