import React from 'react'

const Training = () => {
  return (
    <div>
      Training
    </div>
  )
}

export default Training
