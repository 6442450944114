import React from 'react';

const Grades = () => {
    return (
        <div>
            <h2>This page s coming so0n...</h2>
        </div>
    );
};

export default Grades;