import React from 'react'

const Courses = () => {
  return (
    <div>
      Courses
    </div>
  )
}

export default Courses
